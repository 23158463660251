<template>
  <div>
    <div class="about">
      <div class="container is-max-desktop">
        <br>
        <div class="box">
          <div class="columns is-vcentered">
            <div class="column is-half">
              <b-carousel :autoplay="true" with-carousel-list class="is-shadowless" :indicator="false"
                :overlay="gallery" @click="switchGallery(true)">
                <b-carousel-item v-for="(item, i) in items" :key="i">
                  <figure class="image">
                    <b-image :src="item.image" ratio="4by3" v-if="!gallery"></b-image>
                    <div class="columns is-centered" v-if="gallery">
                      <div class="column is-narrow"><img :src="item.image" class="gimage" /></div>
                    </div>

                  </figure>
                </b-carousel-item>
                <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large" />
                <template #list="props">
                  <b-carousel-list v-model="props.active" v-if="!gallery" :data="items" ratio="4by3" v-bind="al"
                    @switch="props.switch($event, false)" as-indicator />
                </template>
                <template #overlay>
                  <div class="has-text-centered has-text-white">
                    Apartmán
                  </div>
                </template>
              </b-carousel>
            </div>
            <div class="column">
              <h1 class="title">{{title[lang_number]}}</h1>
              <div class="content" v-html="content[lang_number]">
                
              </div>
              <div class="columns is-variable is-2 is-multiline">
                <div class="column is-narrow" v-for="item in info" :key="item.text">
                  <div class="columns is-variable is-1 is-mobile">
                    <div class="column is-narrow d">
                      <b-icon :icon="item.icon" type="is-info" size="is-small">
                      </b-icon>
                    </div>
                    <div class="column is-narrow d">{{item.text[lang_number]}} <b>{{item.data}}</b></div>
                  </div>
                </div>
              </div>
              <b-button type="is-primary" tag="router-link" to="/cenik">{{objednat[lang_number]}}</b-button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.about {
  position:fixed;
  background: url("../assets/whiteback.png");
  background-size: cover;
  background-position: top center;
  z-index: 5;
  margin-bottom: 15px;
  width:100%;
  height: calc(100% - 60px);
  overflow: scroll;
  padding-top: 113px;
}
.gimage
{
  height: calc(100vh - 160px);
  margin-bottom: 60px;
  width: auto;
  
}
.d {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
<script>
  export default {
  created() {
   this.$parent.dark = false;
  },
  data() {
    return {
      title: [
        "Apartmán na peci",
        "Apartment on Peci",
        "난로 위의 아파트",
        "Appartement am Herd",
        "Aparteamentn on īgnīseoyeotēī",
        "Apartament na pieci"
      ],
      objednat: [
        "Objednat",
        "Order",
        "주문하다",
        "Bestellen",
        "Tagyaett",
        "Zamówienie"
      ],
      content:[
        "<b>2 ložnice:</b> první 2 lůžka, druhá 4 lůžka<br><b> Obývací pokoj:</b> Rozkládací pohovka <br>V apartmánu je WiFi zdarma<br><br><b>Vybavení apartmánu:</b><p>výhled na hory, WiFi, TV, topení, skříň šatní, věšák na oblečení, rozkládací pohovka, kuchyňský kout, obývací pokoj s jídelnou, trouba, lednice,myčka, konvice rychlovarná, koupelna se sprchou, ručníky, povlečení.</p>",
        "<b>2 bedrooms: </b> Double bed in the first one, four beds in the second <br> <b> Living room: </b> Sofa bed <br> The apartment has free WiFi <br> <br> <b> Apartment equipment: </b> <p> mountain views, WiFi, TV, heating, wardrobe, clothes rack, sofa, kitchen, oven, refrigerator, dishwasher, kettle, bathroom with shower, towels, bed linen. </p>",
        "<b> 침실: </b> 더블 베드 <br> <b> 거실: </b> 소파 베드 <br> 아파트에 무료 Wi-Fi가 제공됩니다. <br> <br> <b> 아파트 설비: </b> <p> 산 전망, WiFi, TV, 난방 시설, 옷장, 옷걸이, 소파, 주방, 오븐, 냉장고, 식기 세척기, 주전자, 샤워 시설이 있는 욕실, 수건, 침대 시트 </p>",
        "<b>Zwei Schlafzimmer: </b> zwei Betten im ersten, vier Betten im zweiten <br> <b> Wohnzimmer: </b> Schlafsofa <br> Die Wohnung verfügt über kostenloses WLAN <br> <br> <b> Ausstattung der Wohnung: </b> <p> Bergblick, WLAN, TV, Heizung, Kleiderschrank, Kleiderständer, Sofa, Küche, Backofen, Kühlschrank, Spülmaschine, Wasserkocher, Bad mit Dusche, Handtücher, Bettwäsche.</p>",
        "<b>Seobokēteun:</b> Deoboplleo speon<br><b> Baesoyī kētyon:</b> Sopxa <br>In aparteamentēī est lēudderonī WiFi<br><br><b>Aparteamenteun kōmopon:</b><p>Deaktinnael dea cerirēī, WiFi, dōyodaekeun, īsOmi, shwaitēī tuson, īsBeūti tusiseon, sopxan, āllotepepeon, peoleun, eisotuson, PKT, sīlnnaehyaen, keopapaseun kaati sprxamēī, terlakau, īsSpeo.</p>",
        "<b> Dwa sypialnie: </b> w pierwszym dwa łóżka, w drugim cztery łóżka <br> <b> Salon: </b> Rozkładana sofa <br> W apartamencie jest bezpłatne WiFi <br> <br> <b> Wyposażenie apartamentu: </b> <p>widok na góry, WiFi, TV, ogrzewanie, szafa, wieszak na ubrania, sofa, kuchnia, piekarnik, lodówka, zmywarka, czajnik, łazienka z prysznicem, ręczniki, pościel.</p>"
      ],
      info: [
        {
          icon: "bed",
          text: [
            "Počet lůžek: ",
            "Number of beds: ",
            "침대 수: ",
            "Anzahl der Betten: ",
            "Aritō dea speorēī: ",
            "Liczba łóżek: "
          ],
          data: "6+sofa"
        }, {
          icon: "television",
          text: [
            "TV",
            "TV",
            "TV",
            "TV",
            "DD",
            "TV",
          ]
        }, {
          icon: "fullscreen",
          text: [
            "Rozloha místnosti: ",
            "Room area: ",
            "객실 면적: ",
            "Zimmerbereich: ",
            "Kētyon aeriyan: ",
            "Powierzchnia pokoju: ",
          ],
          data: "110 m2"
        }, {
          icon: "door",
          text: [
            "Počet místností:",
            "Number of rooms:",
            "객실 수:",
            "Anzahl der Räume:",
            "Aritō dea kētyorēī:",
            "Liczba pokoi:",
          ],
          data: "3+kk"
        }, {
          icon: "wifi",
          text: [
            "WiFi",
            "WiFi",
            "WiFi",
            "WiFi",
            "WiFi",
            "WiFi",
          ]
        }, {
          icon: "shower",
          text: [
            "Sprcha",
            "Shower",
            "샤워",
            "Dusche",
            "Sprxa",
            "Kabina prysznicowa",
          ]
        }, {
          icon: "toilet",
          text: [
            "WC",
            "WC",
            "화장실",
            "WC",
            "Kadiplaceun",
            "WC",
          ]
        }, {
          icon: "fridge",
          text: [
            "Kuchyně",
            "Kitchen",
            "주방",
            "Küche",
            "Āllotepepeon",
            "Kuchnia",
          ]
        },
      ],
      gallery: false,
      al: {
        hasGrayscale: true,
        itemsToShow: 2,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 4
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 6
          }
        }
      },
      items: [
        {
          title: 'Slide 1',
          image: require('../assets/foto/foto_0.jpg')
        },
        {
          title: 'Slide 2',
          image: require('../assets/foto/foto_1.jpg')
        },
        {
          title: 'Slide 3',
          image: require('../assets/foto/foto_2.jpg')
        },
        {
          title: 'Slide 4',
          image: require('../assets/foto/foto_3.jpg')
        },
        {
          title: 'Slide 5',
          image: require('../assets/foto/foto_4.jpg')
        },
        {
          title: 'Slide 6',
          image: require('../assets/foto/foto_5.jpg')
        },
        {
          title: 'Slide 7',
          image: require('../assets/foto/foto_6.jpg')
        },
        {
          title: 'Slide 8',
          image: require('../assets/foto/foto_7.jpg')
        }
      ]
    }
  },
  methods: {
    getImgUrl: function(i) {
      return require("../assets/foto/foto_"+i+".JPEG");
    },
    switchGallery(value) {
        this.gallery = value
        if (value) {
            return document.documentElement.classList.add('is-clipped')
        } else {
            return document.documentElement.classList.remove('is-clipped')
        }
    }
  },
  computed: {
    lang_number() {
      return this.$parent.lang_number;
    },
    lang() {
      return this.$parent.lang;
    }
  }
  }
</script>